

















import Vue, { PropType } from 'vue';
import StageBlock from '@/components/quiz_maker/StageBlock.vue';
import { QuizTemplateModel } from '@/apis/models/QuizModel';

export default Vue.extend({
  name: 'Stage',
  components: {
    StageBlock,
  },
  props: {
    imageDisplayWidth: {
      type: Number,
      required: false,
      default: 300,
    },
    quizTemplate: {
      type: Object as PropType<QuizTemplateModel>,
      required: false,
      default: null as QuizTemplateModel | null,
    },
    multipleSelect: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    imageRatio(): number {
      if (this.quizTemplate) {
        return this.imageDisplayWidth / this.quizTemplate.baseImage.width;
      }
      return 0;
    },
    stageSize(): object {
      const maxWidth = this.imageDisplayWidth;
      let maxHeight = maxWidth;
      if (this.quizTemplate && this.quizTemplate.baseImage) {
        maxHeight = this.quizTemplate.baseImage.height * this.imageRatio;
      }

      return {
        'max-width': `${maxWidth}px`,
        'max-height': `${maxHeight}px`,
        'min-width': `${maxWidth}px`,
      };
    },
  },
  methods: {
  },
});
