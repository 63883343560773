import { render, staticRenderFns } from "./SolutionImageBtn.vue?vue&type=template&id=55a4d74a&scoped=true&"
import script from "./SolutionImageBtn.vue?vue&type=script&lang=ts&"
export * from "./SolutionImageBtn.vue?vue&type=script&lang=ts&"
import style0 from "./SolutionImageBtn.vue?vue&type=style&index=0&id=55a4d74a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a4d74a",
  null
  
)

export default component.exports