






































































































import Vue from 'vue';

export default Vue.extend({
  name: 'OptionPanel',
  components: {
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fontfamily: '標楷體',
      isLoading: false,
      currentUploadId: 0,
    };
  },
  computed: {
  },
  methods: {
    changeUrl() {
      console.log('預留');
    },
  },
});
