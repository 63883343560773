import { StageOptionModel } from '@/libs/options';

export interface AreaModel {
    x: number;
    y: number;
    width: number;
    height: number;
  }

export interface ContentModel {
    // label: string;
    // type: string;
    // value: string;
    // check: boolean;
    // options: Array<StageOptionModel>;

    textField: TextField;
    imageField: ImageField;
  }

export interface TextField {
  enabled: boolean;
  paddingX: number;
  paddingY: number;
  size: number;
  text: string;
}

export interface ImageField {
  enabled: boolean;
  paddingX: number;
  paddingY: number;
  size: number;
  radius: number;
  url: string;
}

export interface ActionModel {
  type: string;
}

export interface ClickAreaModel {
    label: string;
    area: AreaModel;
    action: ActionModel;
    content: ContentModel;
  }

export interface QuizImage {
  url: string;
  width: number;
  height: number;
}

export interface SolutionImage {
  url: string;
  width: number;
  height: number;
}

export enum Mode {
  PRODUCTION = 'PRODUCTION',
  DEVELOP = 'DEVELOP'
}

export interface QuizTemplateModel {
  templateId: string;
  templateName: string;
  type: string;
  answerSet: string[];
  timeLimit: number;
  baseImage: QuizImage;
  previewImage: QuizImage;
  outputImage: QuizImage;
  clickAreas: Array<ClickAreaModel>;
  mode?: Mode;
  multipleSelect?: boolean;
  difficulty?: string;
  giveAwayScore?: boolean;
  followPreviousQuiz?: boolean;

  enableAudio?: boolean;
  audioUrl?: string;
  enableSolution?: boolean;
  solution?: string;
  solutionImage?: SolutionImage | null;
  audioDuration?: number;
  isBlankFill?: boolean;
  score?: number;
}

/**
 * QuizInstance is built above a QuizTemplate
 *
 * QuizTemplates descript the skeleton of a Quiz,
 * user fills contents into QuizTemplates, uploads it to backend to get uuid,
 * and then it becomes QuizInstance (QuizTemplate + contents + uuid)
 */
export interface QuizInstanceModel extends QuizTemplateModel{
  uuid: string;
}

export interface QuizPreview extends QuizTemplateModel{
  uuid: string;
  answerSet: string[];
  previewImage: QuizImage;
  multipleSelect?: boolean;
  totalScore?: number;
}

export interface GiveAwayModel {
  quizGroupId: string;
  quizId: string;
  giveAway: boolean;
}

export interface SetScore {
  quizGroupId: string;
  quizId: string;
  score: number | undefined;
}
