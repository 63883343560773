




































import StorageApi from '@/apis/StorageApi';
import Vue, { PropType } from 'vue';
import imagedownload from '@/assets/image-download.jpg';
import { StageEventBus } from '@/libs/EventBus';
import { ImageField } from '@/apis/models/QuizModel';
import Utils from '@/libs/utils';
import _ from 'lodash';

export default Vue.extend({
  name: 'StageImage',
  props: {
    width: {
      type: Number,
      // required: true,
      default: 200,
    },
    height: {
      type: Number,
      // required: true,
      default: 200,
    },
    field: {
      type: Object as PropType<ImageField>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isDragging: false,
      errorMessage: '',
      displayPlaceholder: true,
      imagedownload,
      isLoading: false,
      currentUploadId: 0,
    };
  },
  computed: {
    getClasses(): object {
      return { isDragging: this.isDragging };
    },
    imageUrl(): string {
      return this.field.url;
    },
  },
  watch: {
    imageUrl(newUrl: string) {
      if (newUrl !== '') {
        this.isLoading = true;
        this.$store.commit('addStagePendingTask');
        Utils.preloadImage(newUrl).catch(() => {
          this.showErrorMessage('無法讀取檔案');
        }).finally(() => {
          this.$store.commit('finishStagePendingTask');
          this.isLoading = false;
        });
      }
    },
  },
  mounted() {
    StageEventBus.$on('reset-stage', this.reset);
    StageEventBus.$on('before-screenshot', this.beforeScreenshot);
    StageEventBus.$on('after-screenshot', this.afterScreenshot);
  },
  methods: {
    dragOver() {
      this.isDragging = true;
    },
    dragLeave() {
      this.isDragging = false;
    },
    getFileUnit8Array(file: File) {
      const result = file.arrayBuffer().then((buff) => {
        const res = new Uint8Array(buff);
        // console.log(res);
        return res;
      });
      return result;
    },
    checkTypeFromHeader(header: string) {
      let checkHeaderIsImage = false;
      switch (header) {
        case '89504e47':
          checkHeaderIsImage = true;
          break;
        case '47494638':
          checkHeaderIsImage = true;
          break;
        case 'ffd8ffe0':
          checkHeaderIsImage = true;
          break;
        case 'ffd8ffe1':
          checkHeaderIsImage = true;
          break;
        case 'ffd8ffe2':
          checkHeaderIsImage = true;
          break;
        case 'ffd8ffe3':
          checkHeaderIsImage = true;
          break;
        case 'ffd8ffe8':
          checkHeaderIsImage = true;
          break;
        default:
          checkHeaderIsImage = false;
          break;
      }
      console.log(checkHeaderIsImage);
      return checkHeaderIsImage;
    },
    async checkHeaderIsImage(file: File) {
      let header = '';
      let i = 0;
      let isImage = false;
      let arr = await this.getFileUnit8Array(file);
      arr = arr.subarray(0, 4);
      for (i = 0; i < arr.length; i += 1) {
        console.log(arr[i]);
        console.log(i);
        header += arr[i].toString(16);
      }
      isImage = this.checkTypeFromHeader(header);
      return isImage;
    },
    async drop(e: DragEvent) {
      this.isDragging = false;
      if (!e.dataTransfer) {
        return;
      }
      const { files } = e.dataTransfer;
      this.errorMessage = '';
      // allows only 1 file
      if (files.length > 0) {
        const file = files[0];
        // allows image only
        console.log(file.type);
        const headerIsImage = await this.checkHeaderIsImage(file);
        if (file.type.indexOf('image/') >= 0) {
          this.uploadImage(file);
        } else if (headerIsImage) {
          this.uploadImage(file);
        } else {
          this.showErrorMessage('檔案型別有誤');
        }
      }
    },
    async uploadImage(file: File) {
      this.isLoading = true;
      this.$store.commit('addStagePendingTask');

      const myUploadId = _.random(0, 1e10);
      this.currentUploadId = myUploadId;

      // upload image to storage
      try {
        const link = await StorageApi.uploadImage(file);
        if (this.currentUploadId === myUploadId) {
          this.field.url = link;
        }
      } catch (e) {
        this.showErrorMessage('無法讀取檔案');
      } finally {
        this.$store.commit('finishStagePendingTask');
        this.isLoading = false;
      }
    },
    showErrorMessage(message: string) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = '';
      }, 2000);
    },
    reset() {
      this.isDragging = false;
      this.errorMessage = '';
      this.displayPlaceholder = true;
      this.isLoading = false;
      this.currentUploadId = 0;
    },
    beforeScreenshot() {
      this.displayPlaceholder = false;
    },
    afterScreenshot() {
      this.displayPlaceholder = true;
    },
  },
});
