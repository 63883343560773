import api from '@/apis/BaseApi';
import {
  GiveAwayModel, QuizInstanceModel, QuizTemplateModel, SetScore,
} from '@/apis/models/QuizModel';

export default class QuizApi {
  static async create(template: QuizTemplateModel): Promise<QuizInstanceModel> {
    const result = await api.post('quiz/', JSON.stringify(template));
    return result.data.quizInstance;
  }

  static async retrieve(uuid: string): Promise<QuizInstanceModel> {
    const result = await api.get(`quiz/${uuid}`);
    return result.data;
  }

  static async update(uuid: string, instance: QuizInstanceModel): Promise<string> {
    const result = await api.put(`quiz/${uuid}`, JSON.stringify(instance));
    return result.data;
  }

  static async giveAway(updatePayload: GiveAwayModel): Promise<string> {
    const result = await api.post('quiz/giveaway', JSON.stringify(updatePayload));
    return result.data;
  }

  static async setScore(updatePayload: SetScore): Promise<string> {
    const result = await api.post('quiz/set-score', JSON.stringify(updatePayload));
    return result.data;
  }
}
