













































import Vue, { PropType } from 'vue';
import StorageApi from '@/apis/StorageApi';
import { QuizInstanceModel, QuizTemplateModel } from '@/apis/models/QuizModel';

export default Vue.extend({
  components: {
  },
  props: {
    enableAudio: {
      type: Boolean,
      required: true,
    },
    templateOnStage: {
      type: Object as PropType<QuizTemplateModel | QuizInstanceModel>,
      default: null as QuizTemplateModel | QuizInstanceModel | null,
    },
  },
  data() {
    return {
      isDragging: false,
      isLoading: false,
      audio: {
        url: undefined as string | undefined,
        duration: 0 as number,
      },
    };
  },
  computed: {
  },
  watch: {
    enableAudio() {
      this.enable();
    },
    templateOnStage() {
      this.audioLoad();
    },
  },
  methods: {
    reset() {
      this.audio.url = undefined;
      this.audio.duration = 0;
      this.isDragging = false;

      this.templateOnStage.audioUrl = this.audio.url; // 存url
      this.templateOnStage.audioDuration = this.audio.duration; // 存時間
    },
    resetUploader() {
      (this.$refs.uploader as HTMLInputElement).value = '';
    },
    audioLoad() {
      if (this.templateOnStage.audioUrl) {
        if (this.audio.url) {
          (this.$refs.audio as HTMLAudioElement).load();
          // console.log('audioLoad 重載音樂');
        } else {
          // console.log('audioLoad 開啟音樂');
        }
        this.audio.url = this.templateOnStage.audioUrl;
      } else {
        this.audio.url = undefined;
        // console.log('audioLoad沒有音樂');
      }
    },
    enable() {
      if (this.templateOnStage) {
        this.templateOnStage.enableAudio = this.enableAudio;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fileDrop(event: any) {
      event.preventDefault();
      const filesList = event.dataTransfer.files;
      this.addAudioFile(filesList);
      this.isDragging = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fileClick(event: any) {
      const filesList = event.target.files;
      this.addAudioFile(filesList);
    },
    async addAudioFile(filesList: File[]) {
      if (filesList.length > 0) {
        const audioFile = filesList[0];
        await this.getAudioDuration(audioFile);
        if (this.audio.duration > 600000) {
          this.$notify({
            type: 'error',
            title: this.$t('錯誤').toString(),
            text: this.$t('請控制音檔長度，音檔最長10分鐘').toString(),
          });
        } else {
          this.uploadAudio(audioFile);
        }
      }
    },
    getAudioDuration(file: File): Promise<void> {
      return new Promise((resolve) => {
        const url = URL.createObjectURL(file);
        const audioElement = new Audio();
        audioElement.addEventListener('loadedmetadata', () => {
          this.audio.duration = audioElement.duration * 1000;
          resolve();
        });
        audioElement.src = url;
        console.log(this.audio.duration);
      });
    },
    async uploadAudio(file: File) {
      if (!this.templateOnStage) {
        throw new Error('[QuizMaker/uploadAudio] templateOnStage not ready');
      }
      this.isLoading = true;
      this.$store.commit('addStagePendingTask');
      try {
        const url = await StorageApi.uploadAudio(file);
        this.audio.url = url;

        if (this.templateOnStage.audioUrl) {
          if (this.$refs.audio) {
            (this.$refs.audio as HTMLAudioElement).load();
          }
        }
        this.templateOnStage.enableAudio = this.enableAudio; // 把最終是否開啟音檔的結果傳到真資料中
        if (this.enableAudio) { // 如果有開啟音樂 再存以下
          this.templateOnStage.audioUrl = url; // 存url
          this.templateOnStage.audioDuration = this.audio.duration; // 存時間
        }
      } catch (e) {
        // console.log('無法讀取檔案');
        console.error(e);
        this.$notify({
          type: 'error',
          title: this.$t('錯誤').toString(),
          text: this.$t('無法讀取檔案').toString(),
        });
      } finally {
        this.$store.commit('finishStagePendingTask');
        this.isLoading = false;
      }
    },
  },
});
