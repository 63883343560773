






































import { QuizPreview } from '@/apis/models/QuizModel';
import Vue, { PropType } from 'vue';
import PreloadImage from '@/components/PreloadImage.vue';
import DifficultyMark from '@/components/quiz_maker/DifficultyMark.vue';

export default Vue.extend({
  name: 'QuizInstanceCard',
  components: {
    PreloadImage,
    DifficultyMark,
  },
  props: {
    quiz: {
      type: Object as PropType<QuizPreview>,
      required: true,
    },
    enableAudio: {
      type: Boolean,
      required: true,
    },
    difficulty: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      toBeRemove: false,
      toFadeIn: false,
      hasFadeIn: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.toFadeIn = true;
      setTimeout(() => {
        this.hasFadeIn = true;
        this.toFadeIn = false;
      }, 200);
    }, 0);
  },
  methods: {
    removeMe() {
      this.openRemoveModal();
    },
    applyMe() {
      this.$emit('applyMe');
    },
    openRemoveModal() {
      this.$modal.show('dialog', {
        title: this.$t('確定刪除嗎?'),
        text: this.$t('刪掉就不會再回來囉'),
        buttons: [
          {
            title: this.$t('取消'),
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
          {
            title: this.$t('確定'),
            handler: () => {
              this.$modal.hide('dialog');
              this.toBeRemove = true;
              setTimeout(() => {
                this.$emit('removeMe');
                this.toBeRemove = false;
              }, 200);
            },
          },
        ],
      });
    },
  },
});
