

















































































































































































































































































































// @ is an alias to /src
import Vue from 'vue';
import { mapState } from 'vuex';
import html2canvas from 'html2canvas'; // 'html2canvas/dist/html2canvas';
import GuidedTour from '@/views/GuidedTour.vue';
import OpenGuidedTourBtn from '@/components/OpenGuidedTourBtn.vue';
import GuidedTourItem from '@/components/GuidedTourItem.vue';
import EditSolutionModal from '@/components/workspace/EditSolutionModal.vue';

import Stage from '@/components/quiz_maker/Stage.vue';
import OptionPanel from '@/components/quiz_maker/OptionPanel.vue';
import QuizList from '@/components/quiz_maker/QuizList.vue';
import ToolBar from '@/components/quiz_maker/ToolBar.vue';
import QuizAudio from '@/components/quiz_maker/QuizAudio.vue';
import { StageEventBus } from '@/libs/EventBus';
import Utils from '@/libs/utils';

import {
  QuizInstanceModel, Mode,
  QuizPreview, QuizTemplateModel, ContentModel, SolutionImage,
} from '@/apis/models/QuizModel';

import QuizGroupApi from '@/apis/QuizGroupApi';
import StorageApi from '@/apis/StorageApi';
import QuizTemplateApi from '@/apis/QuizTemplateApi';
import QuizApi from '@/apis/QuizApi';

import { Role } from '@/apis/models/RoleModel';
import { QuizGroupModel } from '@/apis/models/QuizGroupModel';

import Auth from '@/libs/auth';
// import i18n from '@/i18n';

enum ToolType {
  Template = '貼圖模板',
  Others = '編輯項目',
  StageOption = '編輯輸入格',
  Guide = '使用導覽',
}

enum Difficulty{
  VeryHard = 'VERY_HARD',
  Hard = 'HARD',
  Medium = 'MEDIUM',
  Easy = 'EASY',
  VeryEasy = 'VERY_EASY',
  NoDifficulty='',
  NoLabel='UN_LABEL',
}

export default Vue.extend({
  name: 'QuizMaker',
  components: {
    Stage,
    OptionPanel,
    QuizList,
    ToolBar,
    QuizAudio,
    OpenGuidedTourBtn,
    GuidedTour,
    GuidedTourItem,
    EditSolutionModal,
  },
  data() {
    return {
      ToolType,
      Difficulty,
      toolArea: ToolType.Template,
      showDetailArea: true,
      enableAudio: false,
      enableSolution: false,
      multipleSelect: false,
      score: 0,
      giveAwayScore: false,
      followPreviousQuiz: false,
      stageWidth: 300,
      quizTemplates: [] as QuizTemplateModel[], // quiz templates are defined in backend database
      quizInstances: [] as QuizPreview[], // quiz instances are created based on quiz templates
      templateOnStage: null as QuizTemplateModel | QuizInstanceModel | null, // the template which user is editing
      currentTemplateIndex: 0,
      copyMessageDisplay: false,
      copyMessageTimeoutId: 0,
      difficulty: '',
      isDifficultyEditor: false,
      isBlankFill: false,
    };
  },
  computed: {
    visibleQuizTemplates(): QuizTemplateModel[] {
      if (this.$store.state.profile && this.$store.state.profile.roles.includes(Role.ADMIN)) {
        return this.quizTemplates;
      }

      return this.quizTemplates.filter((template) => template.mode === Mode.PRODUCTION);
    },
    outputWidth(): number {
      if (this.templateOnStage) {
        return this.templateOnStage.baseImage.width;
      }
      return 0;
    },
    quizGroupId(): string {
      return this.$store.state.currentQuizGroup.uuid;
    },
    quizGroupShortId(): string {
      return this.$store.state.currentQuizGroup.shortId;
    },
    totalScore(): string {
      return this.$store.state.currentQuizGroup.totalScore;
    },
    quizAnswer(): string[] {
      return this.$store.state.stageAnswer;
    },
    stagePendingTaskCount(): number {
      return this.$store.state.stagePendingTaskCount;
    },
    ...mapState({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      localOptions: (state: any) => state.localOptions,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      globalOptions: (state: any) => state.globalOptions,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      stageAnswer: (state: any) => state.stageAnswer,
    }),
    isLoading(): boolean {
      return this.$store.state.isLoading;
    },
    toolAreaText(): string {
      return this.$t(this.toolArea).toString();
    },
    showGuidedTour(): boolean {
      return this.$store.state.showGuidedTour;
    },
    showGuidedTourItem(): boolean {
      if (this.showGuidedTour && this.$route.path === '/manager/quizgroup-maker') {
        return true;
      }
      return false;
    },
    solutionImage(): SolutionImage {
      return this.$store.state.solutionImage;
    },
    solutionContent(): string {
      return this.$store.state.solutionContent;
    },
  },
  watch: {
    localOptions() {
      if (this.localOptions.textField) {
        this.toolArea = ToolType.StageOption;
      }
    },
  },
  mounted() {
    Auth.loginCheck();
    this.$store.commit('resetStage');
    this.loadQuizTemplate();
    this.loadQuizGroup(this.quizGroupId);
  },
  methods: {
    turnOffDifficultyEditor() {
      this.isDifficultyEditor = false;
    },
    setShowGuidedTour(btn: boolean) {
      // this.showGuidedTour = btn;
      this.$store.commit('updateGuidedTour', btn);
    },
    controlMultipleSelect() {
      this.multipleSelect = !this.multipleSelect;

      if (!this.multipleSelect && this.quizAnswer.length > 0) { // 複選變單選的機制
        let ans = this.quizAnswer;
        const lastOne = ans[ans.length - 1];
        ans = [];
        ans.push(lastOne);
        this.$store.commit('updateStageAnswer', ans);
      }
    },
    async addToGroup() {
      if (!this.templateOnStage) {
        throw new Error('[QuizMaker/addToGroup] Unalbe to generate quiz instance based on null/undefiend template');
      }

      if (this.stagePendingTaskCount !== 0) {
        this.$notify({
          type: 'info',
          title: this.$t('尚有檔案在上傳中，請等所有檔案上傳完成後重試...').toString(),
        });
        return;
      }
      console.log('新增的templateOnStage', this.templateOnStage);

      this.$store.commit('updateLoading', true);
      try {
        const template = this.templateOnStage as QuizTemplateModel;

        await this.fillValueToTemplate(template);

        const instance = await QuizApi.create(template);

        const payload = {
          quizGroupId: this.quizGroupId,
          quizId: instance.uuid,
          giveAway: this.giveAwayScore,
        };
        await QuizApi.giveAway(payload);

        this.appendInstance(instance);

        const currTempalte = this.visibleQuizTemplates.find((temp: QuizTemplateModel) => instance.templateId === temp.templateId);
        this.applyTemplateToStage(currTempalte);
      } finally {
        this.$store.commit('updateLoading', false);
      }
    },
    async updateGroupQuiz() {
      if (!this.templateOnStage) {
        throw new Error('[QuizMaker/updateGroupQuiz] Invalid instance');
      }

      if (this.stagePendingTaskCount !== 0) {
        this.$notify({
          type: 'info',
          title: this.$t('尚有檔案在上傳中，請等所有檔案上傳完成後重試...').toString(),
        });
        return;
      }

      this.$store.commit('updateLoading', true);
      try {
        const instance = this.templateOnStage as QuizInstanceModel;

        console.log('update中的templateOnStage', this.templateOnStage);

        await this.fillValueToTemplate(instance);

        await QuizApi.update(instance.uuid, instance);

        const payload = {
          quizGroupId: this.quizGroupId,
          quizId: instance.uuid,
          giveAway: this.giveAwayScore,
        };
        await QuizApi.giveAway(payload);

        // update instance in the quizInstances list
        const instanceIndex = this.quizInstances.map((inst) => inst.uuid).indexOf(instance.uuid);
        // this.quizInstances[instanceIndex] = instance;
        this.$set(this.quizInstances, instanceIndex, instance);

        const currTempalte = this.visibleQuizTemplates.find((temp: QuizTemplateModel) => instance.templateId === temp.templateId);
        this.applyTemplateToStage(currTempalte);
      } finally {
        this.$store.commit('updateLoading', false);
      }
    },
    /**
     * getScreenshotUrl
     * 1. screenshot the stage
     * 2. transform the screenshot into File
     * 3. upload the File to cloud storage
     * 4. and then get the url
     */
    async getScreenshotUrl() {
      // prepare contents and screenshots
      const canvas = await this.screenshotStage();
      const blob = await Utils.getCanvasBlob(canvas);
      const imageLink = await StorageApi.uploadImage(blob, '', true);

      await this.generateHighlightScreenshot(imageLink);

      return imageLink;
    },
    /**
     * screenshotStage
     * convert Stage to png, i.e. do screenshot for Stage
     * return canvas element
     */
    async screenshotStage(): Promise<HTMLCanvasElement> {
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        useCORS: true,
        scale: this.outputWidth / this.stageWidth, // resize the canvas width to 1040 for better resolution
      };
      const el = document.querySelector('#printMe') as HTMLElement;
      if (!el) {
        throw new Error('unable to find printMe element');
      }

      StageEventBus.$emit('before-screenshot');
      await this.$nextTick(); // wait for 'before-screenshot'
      const canvas = await html2canvas(el, options)
        .finally(() => StageEventBus.$emit('after-screenshot'));
      return canvas;
    },
    /**
     * loadQuizTemplate // 取得左邊模板
     * load quiz templates from backend
     */
    loadQuizTemplate() {
      QuizTemplateApi.list().then((data: QuizTemplateModel[]) => {
        data.forEach((element) => {
          element.clickAreas.forEach((clickArea) => {
            clickArea.content = {
              textField: {
                enabled: false,
                paddingX: 80,
                paddingY: 80,
                size: 24,
                text: '',
              },
              imageField: {
                enabled: true,
                paddingX: 80,
                paddingY: 80,
                size: 80,
                radius: 16,
                url: '',
              },
            } as ContentModel;
          });
        });
        this.quizTemplates = data;
        this.applyTemplateToStage(this.visibleQuizTemplates[0]);
      });
    },
    /**
     * loadQuizGroup
     * load quiz list of a quiz group from backend
     */
    loadQuizGroup(quizGroupId: string) {
      if (quizGroupId) {
        QuizGroupApi.retrieve(quizGroupId).then((quizGroup: QuizGroupModel) => {
          this.quizInstances = quizGroup.quizList;
          console.log(this.quizInstances);
        });
      } else {
        this.$notify({
          type: 'error',
          title: '題組載入失敗',
          text: '因為不明原因導致題組載入失敗，請與我們聯繫，我們將協助您排除故障。',
        });
      }
    },
    /**
     * appendInstance
     * add a new quiz instance to quiz array
     */
    appendInstance(instance: QuizInstanceModel) {
      this.quizInstances.push(instance as QuizPreview);
      this.syncQuizList();
    },
    /**
     * syncQuizList
     * upload current quizInstances
     * call this function whenever quizInstances is modified (add, remove, reorder)
     */
    syncQuizList() {
      const payload = {
        quizIdList: this.quizInstances.map((quiz) => quiz.uuid),
      };
      QuizGroupApi.update(this.quizGroupId, payload);
    },
    /**
     * Prepare values and fill values into templateOnStage, get ready for upload (create or update) the template
     */
    async fillValueToTemplate(template: QuizTemplateModel | QuizInstanceModel): Promise<void> {
      // fire all requests at the same time, and collect results when all of them are done
      const results = await Promise.all([
        this.getScreenshotUrl(),
      ]);

      const imageUrl = results[0];
      template.previewImage = Utils.deepcopy(template.baseImage); // copy image height and width
      template.outputImage = Utils.deepcopy(template.baseImage);
      template.previewImage.url = imageUrl;
      template.outputImage.url = imageUrl;

      template.answerSet = this.stageAnswer;

      template.multipleSelect = this.multipleSelect;
      template.isBlankFill = this.isBlankFill;
      template.giveAwayScore = this.giveAwayScore;
      template.followPreviousQuiz = this.followPreviousQuiz;
      template.difficulty = this.difficulty;
      template.score = Number(this.score);

      if (this.multipleSelect && this.quizAnswer.length === 0) { // 複選但沒答案 直接改成複選false
        template.multipleSelect = false;
      }

      if (template.enableAudio && template.audioUrl) {
        template.enableAudio = true;
      } else {
        template.enableAudio = false;
      }

      template.enableSolution = this.enableSolution;

      if (template.enableSolution) {
        template.solution = this.solutionContent;
        template.solutionImage = this.solutionImage;
      } else {
        template.solution = '';
        template.solutionImage = {
          url: '',
          width: 0,
          height: 0,
        };
      }
    },
    applyTemplateToStage(template: QuizTemplateModel| undefined) {
      // reset stage
      StageEventBus.$emit('reset-stage');
      this.$store.commit('resetStage');

      if (template) {
        this.templateOnStage = Utils.deepcopy(template);
      } else {
        this.templateOnStage = Utils.deepcopy(this.visibleQuizTemplates[0]);
      }

      if (this.templateOnStage) {
        this.enableAudio = false;
        this.enableSolution = false;
        this.multipleSelect = false;
        this.giveAwayScore = false;
        this.followPreviousQuiz = false;
        this.difficulty = '';
        this.isDifficultyEditor = false;
        this.isBlankFill = false;
        this.score = 0;
        this.templateOnStage.enableAudio = false;
        this.templateOnStage.audioUrl = undefined;
        this.templateOnStage.audioDuration = 0;
        if (this.templateOnStage.templateName === 'blank filling') {
          this.isBlankFill = true;
        } else {
          this.isBlankFill = false;
        }
        this.templateOnStage.enableSolution = false;
        this.$store.commit('updateSolutionContent', '');
        this.$store.commit('updateSolutionImage', {
          url: '',
          width: 0,
          height: 0,
        });
      }
      // console.log('templateOnStage', this.templateOnStage);
    },
    async applyInstanceToStage(quizPreview: QuizPreview | undefined) {
      // reset stage
      StageEventBus.$emit('reset-stage');
      this.$store.commit('resetStage');

      this.multipleSelect = false; // 還原
      this.giveAwayScore = false; // 還原
      this.followPreviousQuiz = false; // 還原
      this.isDifficultyEditor = false; // 還原
      this.difficulty = '';
      this.isBlankFill = false;
      this.score = 0;

      if (quizPreview) {
        const quiz = await QuizApi.retrieve(quizPreview.uuid);
        console.log('quiz', quiz);
        if (quiz.multipleSelect) {
          this.multipleSelect = quiz.multipleSelect;
        }
        if (quiz.giveAwayScore) {
          this.giveAwayScore = quiz.giveAwayScore;
        }
        if (quiz.followPreviousQuiz) {
          this.followPreviousQuiz = quiz.followPreviousQuiz;
        }
        if (quiz.difficulty) {
          this.difficulty = quiz.difficulty;
        }
        if (quiz.templateName === 'blank filling') {
          this.isBlankFill = true;
        } else {
          this.isBlankFill = false;
        }
        if (quiz.isBlankFill) {
          this.isBlankFill = quiz.isBlankFill;
        }
        if (quiz.score) {
          this.score = quiz.score;
        }
        console.log('取得內容', quiz);
        console.log('this.isBlankFill', quiz.isBlankFill, this.isBlankFill);
        // 幫助區
        if (quiz.answerSet[0] === '') {
          quiz.answerSet = [];
        }
        // -----
        this.templateOnStage = quiz;
        this.$store.commit('updateStageAnswer', quiz.answerSet);
      }

      if (this.templateOnStage) {
        if (this.templateOnStage.enableAudio && this.templateOnStage.audioUrl) {
          this.enableAudio = true;
          this.templateOnStage.enableAudio = true;
        } else {
          this.enableAudio = false;
          this.templateOnStage.enableAudio = false;
        }

        if (this.templateOnStage.enableSolution) {
          this.enableSolution = true;
          this.templateOnStage.enableSolution = true;
        } else {
          this.enableSolution = false;
          this.templateOnStage.enableSolution = false;
        }

        this.$store.commit('updateSolutionContent', this.templateOnStage.solution);
        this.$store.commit('updateSolutionImage', this.templateOnStage.solutionImage);
      }
      this.selectTool(ToolType.Others);
    },
    async generateHighlightScreenshot(baseUrl: string) {
      if (!this.templateOnStage) return;

      const parts = baseUrl.split('/');
      const lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

      StageEventBus.$emit('before-screenshot');
      await this.$nextTick(); // wait for 'before-screenshot'

      for (let i = 0; i < this.templateOnStage.clickAreas.length; i += 1) {
        const clickArea = this.templateOnStage.clickAreas[i];
        if (clickArea.action.type === 'highlight') {
          this.$store.commit('updateStageHighlightLabel', clickArea.label);
          // eslint-disable-next-line no-await-in-loop
          await this.$nextTick(); // wait for 'updateStageHighlightLabel'

          // eslint-disable-next-line no-await-in-loop
          const canvas = await this.screenshotStage();

          // convert, upload image parallelly
          Utils.getCanvasBlob(canvas).then((blob) => {
            StorageApi.uploadImage(blob, `${lastSegment}/${clickArea.label}`, true);
          });
        }
      }

      this.$store.commit('updateStageHighlightLabel', null);
      StageEventBus.$emit('after-screenshot');
    },
    selectTool(toolType: ToolType) {
      this.toolArea = toolType;
      this.showDetailArea = true;
    },
    controlEnableSolution() {
      this.enableSolution = !this.enableSolution;
      this.openEditSolutionModal();
    },
    openEditSolutionModal() {
      if (this.enableSolution && this.templateOnStage) {
        this.$modal.show('edit-solution-modal', {
          templateOnStage: this.templateOnStage,
        });
      }
    },
  },
});
