




























import Vue, { PropType } from 'vue';
import StageText from '@/components/quiz_maker/StageText.vue';
import StageImage from '@/components/quiz_maker/StageImage.vue';
import { StageEventBus } from '@/libs/EventBus';
import { ContentModel } from '@/apis/models/QuizModel';

export default Vue.extend({
  name: 'StageBlock',
  components: {
    StageText,
    StageImage,
  },
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    scaleRatio: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    content: {
      type: Object as PropType<ContentModel>,
      required: false,
      default: () => ({}),
    },
    multipleSelect: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      displayAnswer: true,
    };
  },
  computed: {
    wrapperStyle(): object {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
    quizAnswer(): string[] {
      return this.$store.state.stageAnswer;
    },

    isAnswer(): boolean {
      // return String(this.quizAnswer) === this.label;
      return this.quizAnswer.includes(this.label);
    },
    isHighlight(): boolean {
      const { stageHighlightLabel } = this.$store.state;
      if (stageHighlightLabel === null) return false;

      return stageHighlightLabel === this.label;
    },
  },
  mounted() {
    StageEventBus.$on('reset-stage', this.reset);
    StageEventBus.$on('before-screenshot', this.beforeScreenshot);
    StageEventBus.$on('after-screenshot', this.afterScreenshot);
  },
  methods: {
    reset() {
      this.displayAnswer = true;
    },
    beforeScreenshot() {
      this.displayAnswer = false;
    },
    afterScreenshot() {
      this.displayAnswer = true;
    },
    chooseAnswer() {
      let ans = this.quizAnswer;
      if (this.isAnswer) {
        const index = this.quizAnswer.indexOf(this.label);
        if (ans.length === 1) {
          ans = [];
        } else {
          ans.splice(index, 1);
        }
      } else {
        ans.push(this.label);
      }

      if (!this.multipleSelect) {
        if (ans.length > 0) {
          const lastOne = ans[ans.length - 1];
          ans = [];
          ans.push(lastOne);
        } else {
          ans = [];
        }
      }

      this.$store.commit('updateStageAnswer', ans);
    },
    editOption() {
      this.$store.commit('setLocalOption', this.content);
    },
  },
});
