import { AxiosRequestConfig } from 'axios';
import api from '@/apis/BaseApi';

export default class StorageApi {
  static async uploadImage(blob: Blob, filename = '', needScale = false): Promise<string> {
    const formData = new FormData();
    formData.append('file', blob, 'screenshot.jpg');
    if (filename) {
      formData.append('filename', filename);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } as AxiosRequestConfig;

    const result = await api.post(`storage/image?scale=${needScale}`, formData, config);
    return result.data.imageLink;
  }

  /**
   * Upload audio to cloud storage
   *
   * @param blob file data
   * @param filename filename, if not given, backend will generate a random name for it
   *      Note: backend will not check whether given filename is existed or not, so please make sure it is unique before calling this api
   * @returns url of the uploaded audio
   */
  static async uploadAudio(blob: Blob, filename = ''): Promise<string> {
    const formData = new FormData();
    formData.append('file', blob, 'audio');
    if (filename) {
      formData.append('filename', filename);
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } as AxiosRequestConfig;

    const result = await api.post('storage/audio', formData, config);
    return result.data.audioLink;
  }
}
