





























import draggable from 'vuedraggable';
import QuizInstanceCard from '@/components/quiz_maker/QuizInstanceCard.vue';
import Vue from 'vue';
import { QuizPreview } from '@/apis/models/QuizModel';

export default Vue.extend({
  name: 'QuizList',
  components: {
    draggable,
    QuizInstanceCard,
  },
  props: {
    list: {
      type: Array as () => Array<QuizPreview>,
      default: () => ([]),
    },
    onChange: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
  },
  data() {
    return {
      drag: false,
    };
  },
  computed: {
    dragOptions(): object {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  methods: {
    removeAt(idx: number) {
      this.list.splice(idx, 1);
      if (this.onChange) this.onChange();
    },
  },
});
