











import Vue from 'vue';

enum Difficulty{
  VeryHard = 'VERY_HARD',
  Hard = 'HARD',
  Medium = 'MEDIUM',
  Easy = 'EASY',
  VeryEasy = 'VERY_EASY',
  NoDifficulty='',
  NoLabel='UN_LABEL',
}

export default Vue.extend({
  name: 'QuizInstanceCard',
  components: {
  },
  props: {
    difficulty: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      Difficulty,
    };
  },
  methods: {
  },
});
