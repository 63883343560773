












import Utils from '@/libs/utils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  watch: {
    src() {
      this.preload();
    },
  },
  mounted() {
    this.preload();
  },
  methods: {
    preload() {
      this.isLoading = true;
      Utils.retry(Utils.preloadImage, [this.src], 10, 1000).catch((error) => {
        this.$notify({
          group: 'debug',
          title: 'QuizInstanceCard',
          text: `cannot load ${this.src}, since ${error}`,
        });
        this.$emit('error');
      }).finally(() => {
        this.isLoading = false;
      });
    },
  },
});
