











































import Vue from 'vue';
// import CharacterPicker from '@/components/quiz_maker/toolbar-components/CharacterPicker.vue';

export default Vue.extend({
  name: 'ToolBar',
  components: {
    // CharacterPicker,
  },
  props: {
    multipleSelect: {
      type: Boolean,
      required: true,
    },
    isBlankFill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    quizAnswer(): string[] {
      return this.$store.state.stageAnswer;
    },
    selectState(): string {
      let state = 'noAnswer' as string;
      if (this.multipleSelect && this.quizAnswer.length !== 0) {
        state = 'multipleSelect';
      }
      if (!this.multipleSelect && this.quizAnswer.length === 1) {
        state = 'oneSelect';
      }
      return state;
    },
  },
  methods: {
  },
});
