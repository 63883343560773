





































import Vue, { PropType } from 'vue';
import StorageApi from '@/apis/StorageApi';
import { QuizInstanceModel, QuizTemplateModel } from '@/apis/models/QuizModel';

export default Vue.extend({
  components: {
  },
  props: {
    enableSolution: {
      type: Boolean,
      required: true,
    },
    templateOnStage: {
      type: Object as PropType<QuizTemplateModel | QuizInstanceModel>,
      default: null as QuizTemplateModel | QuizInstanceModel | null,
    },
  },
  data() {
    return {
      isDragging: false,
      isLoading: false,
      image: {
        url: null as string | null,
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
  },
  watch: {
    enableSolution() {
      this.enable();
    },
  },
  methods: {
    resetUploader() {
      (this.$refs.uploader as HTMLInputElement).value = '';
    },
    enable() {
      if (this.templateOnStage) {
        this.templateOnStage.enableSolution = this.enableSolution;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fileDrop(event: any) {
      event.preventDefault();
      const filesList = event.dataTransfer.files;
      this.addImgFile(filesList);
      this.isDragging = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fileClick(event: any) {
      const filesList = event.target.files;
      this.addImgFile(filesList);
    },
    async addImgFile(filesList: File[]) {
      if (filesList.length > 0) {
        const imgFile = filesList[0];
        this.uploadImg(imgFile);
      }
    },
    async uploadImg(file: File) {
      if (!this.templateOnStage) {
        throw new Error('[QuizMaker/uploadImg] templateOnStage not ready');
      }
      this.isLoading = true;
      this.$store.commit('addStagePendingTask');
      try {
        const url = await StorageApi.uploadImage(file);
        this.image.url = url;
        if (this.templateOnStage) {
          this.templateOnStage.solutionImage = {
            url: this.image.url,
            width: 0,
            height: 0,
          };
        }
        console.log('1111', this.templateOnStage);
        this.$emit('updateImage', this.templateOnStage.solutionImage);
      } catch (e) {
        console.error(e);
        this.$notify({
          type: 'error',
          title: this.$t('錯誤').toString(),
          text: this.$t('無法讀取檔案').toString(),
        });
      } finally {
        this.$store.commit('finishStagePendingTask');
        this.isLoading = false;
      }
    },
  },
});
