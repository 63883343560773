








































import Vue from 'vue';
import { WorkspaceFolderModel } from '@/apis/models/WorkspaceObjectModel';
import SolutionImageBtn from '@/components/quiz_maker/SolutionImageBtn.vue';
import { QuizInstanceModel, QuizTemplateModel, SolutionImage } from '@/apis/models/QuizModel';
import Utils from '@/libs/utils';

const MODAL_WIDTH = 1000;
export default Vue.extend({
  components: {
    SolutionImageBtn,
  },
  data() {
    return {
      modalWidth: MODAL_WIDTH,
      solutionContent: '',
      imageInfo: {
        url: '',
        width: 0,
        height: 0,
      } as SolutionImage,
      enableSolution: false,
      templateOnStage: null as QuizTemplateModel | QuizInstanceModel | null,
    };
  },
  computed: {
    currentFolder(): WorkspaceFolderModel {
      return this.$store.state.currentFolder;
    },
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
  },
  methods: {
    beforeOpen(event: any) {
      this.templateOnStage = event.params.templateOnStage;
      if (this.templateOnStage) {
        if (this.templateOnStage.solution) {
          this.solutionContent = this.templateOnStage.solution;
        } else {
          this.solutionContent = '';
        }

        if (this.templateOnStage.solutionImage) {
          this.imageInfo = Utils.deepcopy(this.templateOnStage.solutionImage);
        } else {
          this.imageInfo = {
            url: '',
            width: 0,
            height: 0,
          };
        }

        if (this.templateOnStage.enableSolution) {
          this.enableSolution = this.templateOnStage.enableSolution;
        } else {
          this.enableSolution = true;
        }
      }
    },
    cencelEditor() {
      console.log('取消');
      this.$modal.hide('edit-solution-modal');
    },
    updateSolutionEditor() {
      if (this.templateOnStage) {
        this.templateOnStage.solutionImage = this.imageInfo;
        this.$store.commit('updateSolutionImage', this.imageInfo);
        this.$store.commit('updateSolutionContent', this.solutionContent);

        this.$modal.hide('edit-solution-modal');
      }
    },
    updateImage(img: SolutionImage) {
      if (this.templateOnStage) {
        this.imageInfo = img;
      }
    },
  },
});
